const Videos = {
  Alita: '389025561',
  Battleship: '151074963',
  BrickMansions: '151066016',
  Control: '114289630',
  DjangoUnchained: '151066017',
  EndOfWatch: '151066019',
  Everest: '165233576',
  Filth: '151066018',
  GangsterSquad: '151066020',
  GIJoeRetaliation: '151066024',
  Halloween2: '114289628',
  ImNotThere: '114294914',
  InglouriousBasterds: '114289627',
  Interstellar: '151074992',
  KingsmanGoldenCircle: '389025436',
  KingsmanSecretService: '151074994',
  Logan: '389025248',
  Lucy: '151066025',
  Nine: '114296469',
  Noah: '151074991',
  Piranha3D: '114297298',
  Salinger: '151066021',
  SpringBreakers: '151066022',
  TheDevilsDouble: '151066026',
  TheGreatDebaters: '114383027',
  TheHuntingParty: '114384018',
  TheNannyDiaries: '114384023',
  TheNovemberMan: '151066027',
  TheReader: '114289629',
  TheRoad: '114297297',
  ThisMeansWar: '151066023',
  ThorTheDarkWorld: '151066031',
  TransformersTheLastKnight: '194554295',
  TotalRecall: '151066030',
  VickyCristinaBarcelona: '114388862',
  XMenApocalypse: '165233567',
  YoungOnes: '151066032',
  YouthInRevolt: '114384799',
  ZackAndMiri: '114289631',
  ZeroDarkThirty: '151074993',
};

export default Videos;
