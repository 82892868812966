import React from 'react';
import { Projects } from '../../Components/Projects';

const Web = () => (
  <>
    <Projects />
  </>
);

export default Web;
