import React from 'react';
import Hero from '../../Components/Hero';

const Landing = () => (
  <>
    <Hero />
  </>
);

export default Landing;
