import React from 'react';
import Posters from '../../Components/Movies/Posters';

const DemoReel = () => (
  <>
    <Posters />
  </>
);

export default DemoReel;
