import React from 'react';
import Resume from '../../Components/Resume';

const ExperiencePage = () => (
  <>
    <Resume />
  </>
);

export default ExperiencePage;
